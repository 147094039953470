import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import {
  setRefreshSession,
  setSession,
  getRefreshSession,
  getSession,
} from "src/auth/utils";
import { URLS } from "./urls";
import { BASE_URL } from "./constants";

type RefreshResponse = {
  tokens: {
    access: string;
    refresh: string;
  };
};

const request = axios.create({
  baseURL: BASE_URL,
});

export const refreshTokenRequest = (data: unknown) =>
  request
    .post(URLS.authRenew, data)
    .then((res) => {
      console.log(res.data, "res.data");
      return res.data;
    })
    .catch((e) => {
      setSession("");
      setRefreshSession("");
      window.location.href = "/login";
      throw new Error(e);
    });

const generateSafeRefreshRequest = () => {
  let isRefreshing = false;

  return async (refToken: string) => {
    if (isRefreshing) return null;

    isRefreshing = true;

    const result = await refreshTokenRequest({
      refreshToken: refToken,
    });

    isRefreshing = false;

    return result;
  };
};

const getRefreshToken = generateSafeRefreshRequest();

request.interceptors.request.use((config: AxiosRequestConfig) => {
  const accessToken = getSession();

  if (accessToken && config.url !== URLS.authRenew) {
    if (!config.headers) {
      config.headers = {} as AxiosRequestHeaders;
    }

    config.headers.Authorization = accessToken;
  }

  return config;
});

request.interceptors.response.use(
  (res) => res,
  async (error) => {
    const refreshToken = getRefreshSession();

    if (axios.isAxiosError(error)) {
      console.log(error, refreshToken);

      if (error.response?.status === 401 && refreshToken) {
        try {
          const refreshTokensResponse = await getRefreshToken(refreshToken);

          setSession(refreshTokensResponse.data.access);
          setRefreshSession(refreshTokensResponse.data.refresh);
          const newConfig = setAuthHeader(
            error.config,
            refreshTokensResponse.data.access
          );
          // axios.defaults.headers.common.Authorization = `Bearer ${refreshTokensResponse.data.access}`;
          return await axios(newConfig);
        } catch (e) {
          //  refresh token request failed
          // setSession("");
          // setRefreshSession("");
          // window.location.href = "/login";
          // throw new Error(e);
        }
      }

      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default request;

const setAuthHeader = (config: AxiosRequestConfig | any, token: string) => {
  if (!config.headers) {
    config.headers = {} as AxiosRequestHeaders;
  }

  config.headers.Authorization = token;

  return config;
};
