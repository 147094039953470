import axios from "axios";
// config
import { HOST_API_KEY } from "../config-global";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_KEY });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;

export const getUrlWithQueries = (url: string, params: Object) => {
  const urlParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    urlParams.set(key, value);
  });

  const queryParams = params ? urlParams.toString() : "";

  return `${url}?${queryParams}`;
};
