// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: "/login",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  main: path(ROOTS_DASHBOARD, "/main"),
  categories: path(ROOTS_DASHBOARD, "/categories"),
  three: path(ROOTS_DASHBOARD, "/three"),
  books: {
    root: path(ROOTS_DASHBOARD, "/books"),
    questions: path(ROOTS_DASHBOARD, "/books/questions"),
    add: path(ROOTS_DASHBOARD, "/books/add"),
    six: path(ROOTS_DASHBOARD, "/books/six"),
  },
};
