import { Navigate, useRoutes } from "react-router-dom";
// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// layouts
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
// config
import { PATH_AFTER_LOGIN } from "../config-global";
//
import {
  Page404,
  PageOne,
  PageTwo,
  PageSix,
  PageFour,
  PageFive,
  LoginPage,
  PageThree,
} from "./elements";
import { PATH_DASHBOARD } from "./paths";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: PATH_DASHBOARD.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: PATH_DASHBOARD.main, element: <PageOne /> },
        { path: PATH_DASHBOARD.categories, element: <PageTwo /> },
        { path: PATH_DASHBOARD.books.root, element: <PageThree /> },
        {
          path: PATH_DASHBOARD.books.root,
          children: [
            {
              element: <Navigate to={PATH_DASHBOARD.books.root} replace />,
              index: true,
            },
            { path: PATH_DASHBOARD.books.questions, element: <PageFour /> },
            { path: PATH_DASHBOARD.books.add, element: <PageFive /> },
            { path: "six", element: <PageSix /> },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: "404", element: <Page404 /> }],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
