import { NavSectionProps } from "src/components/nav-section";
import { PATH_DASHBOARD } from "../../../routes/paths";
import SvgColor from "../../../components/svg-color";

export const PAGE_TITLES = {
  [PATH_DASHBOARD.main]: "Bosh sahifa",
  [PATH_DASHBOARD.categories]: "Kategoriyalar",
  [PATH_DASHBOARD.books.root]: "Kitoblar",
  [PATH_DASHBOARD.books.questions]: "Kitoblar savollari",
  [PATH_DASHBOARD.books.add]: "Kitob qo'shish",
  authors: "Mualliflar",
};

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  user: icon("ic_user"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
  category: icon("ic_category"),
  book: icon("ic_book1"),
  author: icon("ic_author"),
};

const navConfig = [
  {
    subheader: "general",
    items: [
      {
        title: PAGE_TITLES[PATH_DASHBOARD.main],
        path: PATH_DASHBOARD.main,
        icon: ICONS.dashboard,
      },
      {
        title: PAGE_TITLES[PATH_DASHBOARD.categories],
        path: PATH_DASHBOARD.categories,
        icon: ICONS.category,
      },
      {
        title: PAGE_TITLES[PATH_DASHBOARD.books.root],
        path: PATH_DASHBOARD.books.root,
        icon: ICONS.book,
        children: [
          {
            title: PAGE_TITLES[PATH_DASHBOARD.books.root],
            path: PATH_DASHBOARD.root,
          },
          {
            title: PAGE_TITLES[PATH_DASHBOARD.books.questions],
            path: PATH_DASHBOARD.books.questions,
          },
          {
            title: PAGE_TITLES[PATH_DASHBOARD.books.add],
            path: PATH_DASHBOARD.books.add,
          },
        ],
      },
    ],
  },

  // {
  //   subheader: "management",
  //   items: [

  //     {
  //       title: books,
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: "Four", path: PATH_DASHBOARD.user.four },
  //         { title: "Five", path: PATH_DASHBOARD.user.five },
  //         { title: "Six", path: PATH_DASHBOARD.user.six },
  //       ],
  //     },
  //   ],
  // },
] as NavSectionProps["data"];

export default navConfig;
