import { Response } from "src/types/response";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import request from "./axiosConfig";
import { URLS } from "./urls";

const fetcher = (url: string) => request(url).then((res) => res.data);

const login = (url: string, { arg }: LoginParams) =>
  request.post(url, arg).then((res) => res.data);

export function useLogin() {
  const mutation = useSWRMutation(URLS.auth, login /* options */);

  return mutation;
}
// type LoginParams = { arg: { refresh_token: string } };
type LoginParams = { arg: { login: string; password: string } };
